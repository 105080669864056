import {useState, useEffect} from 'react'
import { useSignup } from '../hooks/useSignup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const Signup=({role}) => {
	const [name, setName]=useState('')
	const [email, setEmail]=useState('')
	const [password, setPassword]=useState('')
	const [passwordVisible, setPasswordVisible]=useState(false)
	const {signup, error, isLoading, success, setSuccess, setError}=useSignup()

	const handleSubmit=async (e) => {
		e.preventDefault()
		let mail=email
		let pass=password
		
		await signup(name, mail, pass, role, setName, setEmail, setPassword)
    
	}

	const togglePasswordVisiblity = () => {
		setPasswordVisible(passwordVisible => !passwordVisible);
	};

	useEffect(() => {
    // Use useEffect to set a timeout when success or error occurs
    if (success || error) {
      const timeoutId = setTimeout(() => {
        // After 2 seconds, reset both success and error states to hide the messages
        setSuccess(false);
        setError(null);
      }, 3000); // 2000 milliseconds = 2 seconds

      // Return a cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [success, error, setError, setSuccess]);

	return (
		<form className="signup" onSubmit={handleSubmit}>
			<h3>Add {role} Access</h3>

			<label>Name: </label>
			<input
				type="String"
				onChange={(e)=> {
					setName(e.target.value)
				}}
				value={name}
			/>

			<label>Email: </label>
			<input
				type="email"
				onChange={(e)=> setEmail(e.target.value)}
				value={email}
			/>

			<label>Password: </label>
			<div className="passwordField">
				<input
					type={passwordVisible ? "text":"password"}
					onChange={(e)=> setPassword(e.target.value)}
					value={password}
				/>
				<FontAwesomeIcon 
          icon={passwordVisible ? faEye : faEyeSlash} 
          onClick={togglePasswordVisiblity} 
          className="eyeIcon"
        />
		</div>

			<div className='add'>
				<button className={role==='Investor'? 'flipstyle' : ''} disabled={isLoading}>Add {role}</button>
				{error && <div className="errorMessage">{error}</div>}
				{success && (<div className="success">User created. Please send email with credentials.</div>)}
			</div>
		</form>
	)
}

export default Signup