import { useStartupsContext } from "../hooks/useStartupsContext"
import {useParams} from 'react-router-dom'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {SocialIcon} from 'react-social-icons'


const StartupProfile = () => {

  const {startups}= useStartupsContext()
  const {id} =useParams()

  const startup =startups.find(startup => startup._id ===id)
  const index=startups.indexOf(startup)
  //console.log(startup)

  const formatDate= (date) => {
    const object=new Date(date)
    const options={day: '2-digit', month: '2-digit', year: 'numeric'}
    return object.toLocaleDateString('pt-PT', options)
  }

  return (
    <div className="full-width">
      <div className="pagenavigate">
        {index !== 0 && (
          <Link
            to={index === 0 ? '/home' : `/startup/${startups[index - 1]._id}`}
            className="inpagelink"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="caret-sides" />
            Back
          </Link>
        )}
        {index !== startups.length - 1 && (
          <Link
            to={`/startup/${startups[index + 1]._id}`}
            className="inpagelink"
          >
            Next
            <FontAwesomeIcon icon={faChevronRight} className="caret-sides" />
          </Link>
        )}
      </div>
  
      <div className="profilecontainer">
        <div className="profilesubcontainer">
          <div className="headercontainer">
            <img className="imgInsideContainer" src={`${process.env.REACT_APP_BACKEND_URL}/${startup.logo}`} alt="Startup Logo" />
          </div>
          <div className="headercontainer">
            <div>
              <h1>{startup.name}</h1>
              {startup.website && (<p><strong>Website: </strong> <a href={`https://${startup.website}`} target="_blank" rel="noopener noreferrer" className="inpagelink">{startup.website}</a></p>)}
              <p><strong>HQ: </strong>{startup.hqCity}, {startup.hqCountry}</p>
              <p><strong>Incorporated on: </strong>{formatDate(startup.dateOfIncorporation)}</p>
            </div>
          </div>
        </div>
        
        <div className='profilesubcontainer'>
          <div className="profileblock">
            <h2>Mission</h2>
            <p>{startup.mission}</p>
          </div>
          <div className="profileblock">
            <h2>Vision</h2>
            <p>{startup.vision}</p>
          </div>
          <div className="profileblock">
            <h2>Market</h2>
            <p><strong>Primary: </strong>{startup.primaryMarket}</p>
            {(startup.secondaryMarkets.length-1) ? (<p><strong>Secondary: </strong> {startup.secondaryMarkets.slice(1).join(', ')}</p>) : null } 
            <p><strong>Industry: </strong>{startup.industry}</p>
          </div>
        </div>
        
        <div className='profilesubcontainer'>
            <div>
              <h2>Founder(s)</h2>
              <div className='founders'>
                <p>{`${startup.founder1name}`} </p>
                <SocialIcon url="www.linkedin.com" href={`https://www.linkedin.com/in/${startup.founder1linkedin}`} target="_blank" style={{ height: 25, width: 25, margin:'5px' }} />
              </div>
              {startup.founder2name && (
                <div className='founders'>
                  <p>{`${startup.founder2name}`}  </p>
                <SocialIcon url="www.linkedin.com" href={`https://www.linkedin.com/in/${startup.founder2linkedin}`} target="_blank" style={{ height: 25, width: 25, margin:'5px' }} />
              </div>)}
              {startup.founder3name && (
                <div className='founders'>
                  <p>{`${startup.founder3name}`}  </p>
                <SocialIcon url="www.linkedin.com" href={`https://www.linkedin.com/in/${startup.founder3linkedin}`} target="_blank" style={{ height: 25, width: 25, margin:'5px' }} />

              </div>)}
            </div>
          <div>
            <h2>Employees</h2>
            <div className='center'><h1>{startup.numberOfEmployees}</h1></div>
          </div>
          <div>
            <h2>Amount to Raise</h2>
            <div className='center'><h1>${startup.amountBeingRaised}</h1></div>
          </div>
        </div>

        <div className='sdgcontainer'>
    <div className='primarySDG'>
        <img className="imgInsideContainer" src={`/SDGIcons/${startup.primarySDG.split(':')[0].trim()}.png`} alt='SDG'/>
    </div>
    {(startup.otherSDG.length > 1) ? (startup.otherSDG.slice(1).map((sdg) => (
        <div key={sdg} className='otherSDG'>
            <img className="imgInsideContainer" src={`/SDGIcons/${sdg.split(':')[0].trim()}.png`} alt='SDG'/>
        </div>
    ))) : null }


        </div>

        <h2 className="green">Financials</h2>
        <div className="profilesubcontainer">
          <div className="financialblock">
            <p><strong>Revenue | Last Fiscal Year: </strong>{`$${startup.revenuesLastYear}`}</p>
            <p><strong>Revenue | Last 3 Months: </strong>{`$${startup.revenuesLast3Months}`}</p>
            <p><strong>Annual Recurring Revenue: </strong>{`$${startup.annualRecurringRevenue}`}</p>
          </div>
          <div className="financialblock middle">
            <p><strong>Latest Valuation: </strong>{`$${startup.latestValuation}`}</p>
            <p><strong>Latest Valuation Date: </strong>{formatDate(startup.latestValuationDate)}</p>
            {startup.ipRegistered && <p><strong>IP Registered: </strong>{startup.ipRegistered}</p>}
          </div>
          <div className="financialblock">
            <p><strong>Funding Type: </strong>{startup.fundingType}</p>
            <p><strong>Min Ticket: </strong>{`$${startup.minTicket}`}</p>
          </div>
        </div>
        
        <h2 className="green">Files</h2>
        <div className='files'>
          <div className='filecontainer'>
            <p>Pitch Deck</p>
            <a href={`${process.env.REACT_APP_BACKEND_URL}/${startup.pitchDeck}`} target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/PDF_file_icon.svg.png`} alt="Pitch Deck Icon" style={{ width: '80px', height: '80px' }} />
            </a>
          </div>
          <div className='filecontainer'>
            <p>Business Case</p>
            <a href={`${process.env.REACT_APP_BACKEND_URL}/${startup.businessCase}`} target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.REACT_APP_BACKEND_URL}/uploads/PDF_file_icon.svg.png`} alt="Business Case Icon" style={{ width: '80px', height: '80px' }} />
            </a>
          </div>
        </div>

      </div>

      
    </div>
  );
}  

export default StartupProfile