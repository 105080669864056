import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useUsersContext } from '../hooks/useUsersContext';
const {formatDistanceToNowStrict} =require('date-fns')

export function UserListing({ user }) {

  const {dispatch} = useUsersContext()
  const date= new Date(user.createdAt)

  
  const handleTrashClick = async (e) => {
    e.preventDefault()
    if (!user) {
      return 
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/users/${user._id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer: ${user.token}`
      }
    })
    const json =await response.json()
    //console.log(json)
    

    if (response.ok) {
      dispatch({type: 'DELETE_USER', payload: json})
    }
  }



  return (

      <div className="user-listing">
        <div className="listing-column">
          <p>{user.name}</p>
        </div>
        <div className="listing-column" id="listing-email">
          <p>{user.email}</p>
        </div>
        <div className="listing-column" id="listing-registered">
					{user.registered && user.registered ? (<FontAwesomeIcon icon={faCheck} className="green"/>) : (<FontAwesomeIcon icon={faXmark} className="red"/>)}          
        </div>
        <div className="listing-column" id="listing-createdat">
					{user.createdAt && <p>{formatDistanceToNowStrict(date, {addSuffix: true})}</p>}        
        </div>
        <div className="listing-column">
          <div className="trashcontainer">
            <FontAwesomeIcon icon={faTrash} className="green listing-trash" onClick={handleTrashClick}/>
          </div>
        </div>
      </div>
  );
};

export function UserListingHeader() {

	return (

      <div className="listing-header">
        <div className="listing-column">
          <p>Name</p>
        </div>
        <div className="listing-column" id="listing-email">
          <p>Email</p>
        </div>
        <div className="listing-column" id="listing-registered">
					<p>Registered</p>         
        </div>
        <div className="listing-column" id="listing-createdat">
					<p>Signed Up</p>         
        </div>
        <div className="listing-column">
        </div>
      </div>
  );
};
