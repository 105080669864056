import {useState, useEffect} from 'react'
import { useAuthContext } from '../hooks/useAuthContext';



const Feedback=() => {
    const { user } = useAuthContext();

	const [feedback1Text, setFeedback1Text]=useState('')
	const [feedback2Text, setFeedback2Text]=useState('')
	const [success, setSuccess]=useState(null)
	const [error, setError]=useState(null)

    const show_feedback2 = user.role === 'Investor' || user.role === 'Admin';

	

	const handleSubmit = async (e) => {
        e.preventDefault()
            setError(null)
            setSuccess(null)
            
            const sheet_body= {
                sheet_values: [user.name, user.email, user.role, feedback1Text, show_feedback2 ? feedback2Text : '-']

            }
    
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/feedback`, {
                    method: 'POST',
                    body: JSON.stringify(sheet_body), 
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                        'Content-Type': 'application/json'
                    }
                })
                const json = await response.json()
                console.log(json)
                
                if (!response.ok) {
                    setError(json.error)
                }
                if (response.ok) {
                    setFeedback1Text('')
                    setFeedback2Text('')
                    setSuccess(true)
                    setError(null)
                    
                }
            } catch {
                setError('There is a server error. Please notify us.')
            }
                
      };
    
      useEffect(() => {
        // Use useEffect to set a timeout when success or error occurs
        if (success || error) {
          const timeoutId = setTimeout(() => {
            // After 2 seconds, reset both success and error states to hide the messages
            setSuccess(false);
            setError(null);
          }, 3000); // 2000 milliseconds = 2 seconds
    
          // Return a cleanup function to clear the timeout if the component unmounts
          return () => clearTimeout(timeoutId);
        }
      }, [success, error, setError, setSuccess]);
    

	return (
		<form className="feedback" onSubmit={handleSubmit}>
		    <h2>Feedback</h2>
			<label>
				Please share your experience in capturing your own data with this platform and suggestions for improvement.
				<textarea
					name="feedback1"
					onChange={(e)=> {setFeedback1Text(e.target.value)}}
				    value={feedback1Text}
					className={`feedback-text`}
				/>
			</label>

            {(show_feedback2) && (
                <label>
				Please share your experience in using the platform and the available information on startups.
				<textarea
					name="feedback2"
					onChange={(e)=> {setFeedback2Text(e.target.value)}}
				    value={feedback2Text}
					className={`feedback-text`}
				/>
			</label>
            )}

			
            <div className='add'>
				<button>Submit</button>
				{error && <div className="errorMessage">{error}</div>}
				{success && (<div className="success">Thank you for your feedback!</div>)}
			</div>

		</form>
	)
}

export default Feedback