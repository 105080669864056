import { useAuthContext } from '../hooks/useAuthContext';
import StartupProfileSingle from "../components/StartupProfileSingle"

const ThankYou=({role}) => {
	const {user} =useAuthContext()

	return (
		<div className="thanks">
			{user && user.role === 'Startup' ? (
				<div className="full-width">
					<div className="thanks-text">
						<h1 className='green'>Thank you for registering!</h1>
						<h3>
							This is what investors see. There is no way to update the profile at the moment.
						</h3>
					</div>
					<StartupProfileSingle />
				</div>
			) : (
				<div>
					<h1 className="thanks">Registered new startup successfully!</h1>
				</div>
			)}
		</div>
	);

}
	

export default ThankYou