import { useState, useEffect } from 'react';
import {useStartupsContext} from '../hooks/useStartupsContext'
import { useAuthContext } from '../hooks/useAuthContext';
import {useNavigate} from 'react-router-dom'

import Dropdown from '../components/Dropdown'
import DropdownPlus from '../components/DropdownPlus' 
import FileUpload from '../components/FileUpload'
import options from '../objects/dropdownOptions'

function StartupForm() {

	//set states for the dropdown menus
	const [selectedHqCountry, setSelectedHqCountry]=useState("Choose One")
	const [selectedPrimaryMarket, setSelectedPrimaryMarket]=useState("Choose One")
	const [selectedSecondaryMarkets, setSelectedSecondaryMarkets]=useState(["Choose up to 5"])
	const [selectedPrimarySDG, setSelectedPrimarySDG]=useState("Choose One")
	const [selectedOtherSDG, setSelectedOtherSDG]=useState(["Choose up to 5"])
	const [selectedIndustry, setSelectedIndustry]=useState("Choose One")
	const [selectedFundingType, setSelectedFundingType]=useState("Choose One")


	//file upload
	const [logo, setLogo] = useState(null);
  const [pitchDeck, setPitchDeck] = useState(null);
  const [businessCase, setBusinessCase] = useState(null);

	const handleFileUpload = (file, field) => {
    if (field === 'logo') {
      setLogo(file);
    } else if (field === 'pitchDeck') {
      setPitchDeck(file);
    } else if (field === 'businessCase') {
      setBusinessCase(file);
    }
  };

	//other setups
	const navigate=useNavigate()
  const {dispatch}=useStartupsContext()
	const [error, setError] = useState(null)
  const [emptyFields, setEmptyFields]=useState([])
	const {user}=useAuthContext()
  const [formData, setFormData] = useState({
		user_id: user._id,
    name: '',
    website: '',
    founder1name: '',
		founder1linkedin:'',
		founder2name: '',
		founder2linkedin:'',
		founder3name: '',
		founder3linkedin:'',
    dateOfIncorporation: '',
    numberOfEmployees: '',
    revenuesLastYear: '',
    revenuesLast3Months: '',
    annualRecurringRevenue: '',
    hqCity: '',
    hqCountry: '',
    primaryMarket: '',
    secondaryMarkets: [],
    mission: '',
    vision: '',
    industry: '',
    primarySDG: '',
    otherSDG: [],
    ipRegistered: '',
    latestValuation: '',
    latestValuationDate: '',
    amountBeingRaised: '',
    fundingType: '',
    minTicket: '',
  });

	//set context
	useEffect(() => {
    const fetchStartups = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/startups`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if (response.ok) {
        dispatch({type: 'SET_STARTUPS', payload: json})
      }
    }

    if (user) {
      fetchStartups()
    }

  }, [dispatch, user])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

	//Submit!
  const handleSubmit = async (e) => {
    e.preventDefault()
		setError(null)
		
		const startup={
			...formData,
			fundingType: selectedFundingType,
			primarySDG: selectedPrimarySDG,
			otherSDG: selectedOtherSDG,
			primaryMarket: selectedPrimaryMarket,
			secondaryMarkets: selectedSecondaryMarkets,
			industry: selectedIndustry,
			hqCountry: selectedHqCountry
			}

		//console.log(startup)
		
		const formDataToSend = new FormData();

  	//Append the files to the FormData object
		formDataToSend.append('logo', logo);
    formDataToSend.append('pitchDeck', pitchDeck);
    formDataToSend.append('businessCase', businessCase);

  	//Append the rest of the startup data
  	for (const key in startup) {
				if (key==='otherSDG') {
					startup.otherSDG.forEach((option) => {
						formDataToSend.append('otherSDG', option);
					});
				}
				else if (key==='secondaryMarkets') {
					startup.secondaryMarkets.forEach((option) => {
						formDataToSend.append('secondaryMarkets', option);
					});
				}
				else {
					formDataToSend.append(key, startup[key]); 
				}
  	}
		

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/startups`, {
				method: 'POST',
				body: formDataToSend,
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			})
			const json = await response.json()
			//console.log(json)
			
			if (!response.ok) {
				setError(json.error)
				setEmptyFields(json.emptyFields || [])
			}
			if (response.ok) {
				setEmptyFields([])
				setError(null)
				//setFormData()
				dispatch({type: 'CREATE_STARTUP', payload: json})
				navigate('/thankyou')
			}
		} catch {
			setError('Network Error: Cannot connect to backend')
		}
			
  };

  return (
    <form encType="multipart/form-data" className='startupform' onSubmit={handleSubmit}>
			<h2>Startup Data Capture</h2>
			<p className="mandatory"><span className="required">*</span>means a mandatory field</p>

			<label>
				Company Name<span class="required">*</span>:
				<input
					type="text"
					name="name"
					value={formData.name}
					onChange={handleInputChange}
					className={emptyFields.includes('name') ? 'error':''}
				/>
			</label>

			<label>
				Website:
				<input
					type="text"
					name="website"
					value={formData.website}
					onChange={handleInputChange}
				/>
			</label>

			<label>
				Founder 1 Name<span class="required">*</span>:
				<input
					type="text"
					name="founder1name"
					value={formData.founder1name}
					onChange={handleInputChange}
					className={emptyFields.includes('founder1name') ? 'error':''}
				/>
			</label>

			<label>
				Founder 1 LinkedIn<span class="required">*</span>:
				<input
					type="text"
					name="founder1linkedin"
					value={formData.founder1linkedin}
					onChange={handleInputChange}
					className={emptyFields.includes('founder1linkedin') ? 'error':''}
				/>
			</label>

			<label>
				Founder 2 Name:
				<input
					type="text"
					name="founder2name"
					value={formData.founder2name}
					onChange={handleInputChange}
				/>
			</label>

			<label>
				Founder 2 LinkedIn:
				<input
					type="text"
					name="founder2linkedin"
					value={formData.founder2linkedin}
					onChange={handleInputChange}
				/>
			</label>

			<label>
				Founder 3 Name:
				<input
					type="text"
					name="founder3name"
					value={formData.founder3name}
					onChange={handleInputChange}
				/>
			</label>

			<label>
				Founder 3 LinkedIn:
				<input
					type="text"
					name="founder3linkedin"
					value={formData.founder3linkedin}
					onChange={handleInputChange}
				/>
			</label>

			<label>
				Date of Incorporation<span class="required">*</span>:
				<input
					type="date"
					name="dateOfIncorporation"
					value={formData.dateOfIncorporation}
					onChange={handleInputChange}
					className={emptyFields.includes('dateOfIncorporation') ? 'error':''}
				/>
			</label>

			<label>
				Number of Employees<span class="required">*</span>:
				<input
					type="String"
					name="numberOfEmployees"
					value={formData.numberOfEmployees}
					onChange={handleInputChange}
					className={emptyFields.includes('numberOfEmployees') ? 'error':''}
				/>
			</label>

			<label>
				Revenues Last Year<span class="required">*</span>:
				<input
					type="text"
					name="revenuesLastYear"
					value={formData.revenuesLastYear}
					onChange={handleInputChange}
					className={emptyFields.includes('revenuesLastYear') ? 'error':''}
				/>
			</label>

			<label>
				Revenues Last 3 Months<span class="required">*</span>:
				<input
					type="text"
					name="revenuesLast3Months"
					value={formData.revenuesLast3Months}
					onChange={handleInputChange}
					className={emptyFields.includes('revenuesLast3Months') ? 'error':''}
				/>
			</label>

			<label>
				Annual Recurring Revenue<span class="required">*</span>:
				<input
					type="text"
					name="annualRecurringRevenue"
					value={formData.annualRecurringRevenue}
					onChange={handleInputChange}
					className={emptyFields.includes('annualRecurringRevenue') ? 'error':''}
				/>
			</label>

			<label>
				HQ City<span class="required">*</span>:
				<input
					type="text"
					name="hqCity"
					value={formData.hqCity}
					onChange={handleInputChange}
					className={emptyFields.includes('hqCity') ? 'error':''}
				/>
			</label>

			<label>
				HQ Country<span class="required">*</span>:
				<Dropdown selected={selectedHqCountry}  
				setSelected={setSelectedHqCountry} 
				options={options.countries} 
				missing={emptyFields.includes('hqCountry') ? true : false} />
			</label>

			<label>
				Primary Market<span class="required">*</span>:
				<Dropdown selected={selectedPrimaryMarket}  
				setSelected={setSelectedPrimaryMarket} 
				options={options.countries}
				missing={emptyFields.includes('primaryMarket') ? true : false} />
			</label>

			<label>
				Secondary Markets<span class="required">*</span>:
				<DropdownPlus selected={selectedSecondaryMarkets}  
				setSelected={setSelectedSecondaryMarkets} 
				options={options.countries} 
				primaryMarket={selectedPrimaryMarket} 
				missing={emptyFields.includes('secondaryMarkets') ? true : false}
				numberOfOptions={5} />
			</label>
			<label>
				Mission<span class="required">*</span>:
				<textarea
					name="mission"
					value={formData.mission}
					onChange={handleInputChange}
					className={emptyFields.includes('mission') ? 'error':''}
				/>
			</label>

			<label>
				Vision<span class="required">*</span>:
				<textarea
					name="vision"
					value={formData.vision}
					onChange={handleInputChange}
					className={emptyFields.includes('vision') ? 'error':''}
				/>
			</label>

			<label>
				Industry<span class="required">*</span>:
				<Dropdown selected={selectedIndustry}  
				setSelected={setSelectedIndustry} 
				options={options.industries} 
				missing={emptyFields.includes('industry') ? true : false}/>
			</label>

			<label>
				Primary SDG<span class="required">*</span>:
				<Dropdown selected={selectedPrimarySDG}
				  setSelected={setSelectedPrimarySDG}
					options={options.sdg}
					missing={emptyFields.includes('primarySDG') ? true : false} />
			</label>

			<label>
				Other SDGs<span class="required">*</span>:
				<DropdownPlus selected={selectedOtherSDG}  
				setSelected={setSelectedOtherSDG} 
				primaryMarket={selectedPrimarySDG} 
				options={options.sdg} 
				missing={emptyFields.includes('otherSDG') ? true : false}
				numberOfOptions={4} />

			</label>

			<label>
				IP Registered:
				<input
					type="text"
					name="ipRegistered"
					value={formData.ipRegistered}
					onChange={handleInputChange}
					className={emptyFields.includes('ipRegistered') ? 'error':''}
				/>
			</label>

			<label>
				Latest Valuation<span class="required">*</span>:
				<input
					type="text"
					name="latestValuation"
					value={formData.latestValuation}
					onChange={handleInputChange}
					className={emptyFields.includes('latestValuation') ? 'error':''}
				/>
			</label>

			<label>
				Latest Valuation Date<span class="required">*</span>:
				<input
					type="date"
					name="latestValuationDate"
					value={formData.latestValuationDate}
					onChange={handleInputChange}
					className={emptyFields.includes('latestValuationDate') ? 'error':''}
				/>
			</label>

			<label>
				Amount Being Raised<span class="required">*</span>:
				<input
					type="text"
					name="amountBeingRaised"
					value={formData.amountBeingRaised}
					onChange={handleInputChange}
					className={emptyFields.includes('amountBeingRaised') ? 'error':''}
				/>
			</label>

			<label>
				Funding Type<span class="required">*</span>:
				<Dropdown selected={selectedFundingType}  
				setSelected={setSelectedFundingType} 
				options={options.fundingOptions}
				missing={emptyFields.includes('fundingType') ? true : false} />
			</label>

			<label>
				Minimum Ticket<span class="required">*</span>:
				<input
					type="text"
					name="minTicket"
					value={formData.minTicket}
					onChange={handleInputChange}
					className={emptyFields.includes('minTicket') ? 'error':''}
				/>
			</label>

			<div>
  			<h2>Startup Files</h2>
				<div className="file-upload">
					<label>Logo<span class="required">*</span>:</label>
      		<FileUpload onFileUpload={(file) => handleFileUpload(file, 'logo')} missing={emptyFields.includes('logo') ? true : false}/>
				</div>
				<div className="file-upload">
					<label>Pitch Deck<span class="required">*</span>:</label>
      		<FileUpload onFileUpload={(file) => handleFileUpload(file, 'pitchDeck')} missing={emptyFields.includes('pitchDeck') ? true : false}/>
				</div>
				<div className="file-upload">
					<label>Business Plan<span class="required">*</span>:</label>
      		<FileUpload onFileUpload={(file) => handleFileUpload(file, 'businessCase')} missing={emptyFields.includes('businessCase') ? true : false}/>
				</div>
			</div>

			<button>Submit</button>
      {error && <div className="errorMessage">{error}</div>}

</form>



  );
}

export default StartupForm;
