import { useState } from "react";
import { useUsersContext } from '../hooks/useUsersContext'

export const useSignup=()=> {
	const {dispatch} =useUsersContext()
	const [error, setError] =useState(null)
	const [success, setSuccess]=useState(null)
	const [isLoading, setIsLoading]=useState(null)


	const signup =async (name, email, password, role, setName, setEmail, setPassword) => {
		setIsLoading(true)
		setError(null)
		setSuccess(null)

		const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/signup`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({name, email, password, role})
		})
		const json =await response.json()
		//console.log(json)

		if (!response.ok) {
			setIsLoading(false)
			setError(json.error)
		}

		if (response.ok) {
			//send email
			setName('')
			setEmail('')
			setPassword('')
			setSuccess(true)
			setIsLoading(false)
			dispatch({type: 'CREATE_USER', payload:json})
		}
	}

	return {signup, isLoading, error, success, setError, setSuccess}
}