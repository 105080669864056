import { useEffect} from "react"
import StartupPreview from "../components/StartupPreview"
import { useStartupsContext } from "../hooks/useStartupsContext"
import {useAuthContext} from "../hooks/useAuthContext"


const InvestorHome = () => {
  const {startups, dispatch}= useStartupsContext()
  const {user} = useAuthContext()

  useEffect(() => {
    const fetchStartups = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/startups`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if (response.ok) {
        dispatch({type: 'SET_STARTUPS', payload: json})
      }
    }

    if (user) {
      fetchStartups()
    }

  }, [dispatch, user])

  return (
    <div className="home">
      <div className="startups">
        {startups && startups.map(startup => (
          <StartupPreview startup={startup} key={startup._id} />
        ))}
      </div>
    </div>
  )
}

export default InvestorHome