import {Link} from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useInvestorsContext } from '../hooks/useInvestorsContext';

const InvestorPreview = ({ investor }) => {

  const {user}= useAuthContext()
  const {dispatch} = useInvestorsContext()


  const formatDate= (date) => {
    const object=new Date(date)
    const options={day: '2-digit', month: '2-digit', year: 'numeric'}
    return object.toLocaleDateString('pt-PT', options)
  }
  
  const handleTrashClick = async (e) => {
    e.preventDefault()
    if (!user) {
      return 
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/investors/${investor._id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer: ${user.token}`
      }
    })
    //console.log(response)
    const json =await response.json()
    

    if (response.ok) {
      dispatch({type: 'DELETE_INVESTOR', payload: json})
    }
  }



  return (

    <Link to={`/investor/${investor._id}`} className='no-underline'>
      <div className="entity-details">
        <div className="preview-header">
          <h4>{investor.name}</h4>
          {user.role==='Admin' && (<div className="trashcontainer">
            <FontAwesomeIcon icon={faTrash} className="green" onClick={handleTrashClick}/>
          </div>)}
        </div>
        <div className='flex'>
          <div>
            <p><strong>Portfolio Manager: </strong>{investor.portfolioManagerName}</p>
            <p><strong>Incorporation: </strong>{formatDate(investor.dateOfIncorporation)}</p>
            <p><strong>Employees: </strong>{investor.numberOfEmployees}</p>
          </div>
          <div>
            <p><strong>AUM Last Fiscal Year: </strong>{investor.aumLastYear}</p>
            <p><strong>AUM Last 3 Months: </strong>{investor.aumLast3Months}</p>
            <p><strong>Investment Type: </strong>{investor.investmentType}</p>
          </div>
          <div>
            <p><strong>HQ City: </strong>{investor.hqCity}</p>
            <p><strong>HQ Country: </strong>{investor.hqCountry}</p>
            <p><strong>Minimum Ticket: </strong>${investor.minTicket}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default InvestorPreview;
