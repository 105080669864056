import { StartupsContext } from "../context/startupContext"
import { useContext } from "react"

export const useStartupsContext = () => {
  const context = useContext(StartupsContext)

  if(!context) {
    throw Error('useStartupContext must be used inside a StartupContextProvider')
  }

  return context
}