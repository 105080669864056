//Dropdown that allows more options
import { useState, useEffect, useRef } from "react";


function DropdownPlus({ selected, setSelected, options, numberOfOptions, primaryMarket, missing }) {
  const [isActive, setIsActive] = useState(false);

	//close when out of bounds
	const dropdownRef = useRef(null);

  useEffect(() => {
    const closeDropdownOnOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener('click', closeDropdownOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeDropdownOnOutsideClick);
    };
  }, []);

	//logic for selected options
  const toggleOption = (option) => {
    if (option===primaryMarket) {
      return
    }
    if (selected.includes(option)) {
      setSelected(selected.filter((item) => item !== option));
    } else {
			if (selected.length<=numberOfOptions){
				setSelected([...selected, option]);
			}
    }
  };

  return (
    <div className='dropdown' ref={dropdownRef}>
      <div className={`dropdown-btn ${missing ? 'error':''}`} onClick={() => setIsActive(!isActive)}>
        <div className={(selected.length > 1) ? 'selected-options':'' }>
				{(selected.length > 1) ? selected.slice(1).join(', ') : ['Choose up to 5']}
				</div>
        <span className="caret-down"></span>
      </div>
      {isActive && (
        <div className="dropdown-content">
          {options.map((option) => (
            <div
              key={option}
              onClick={() => toggleOption(option)}
              className={`dropdown-item ${selected.includes(option) ? 'selected' : ''}`}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownPlus
