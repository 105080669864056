import { NavLink } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext'
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
  const {user}=useAuthContext()
  const location=useLocation()

  const isThankYouPage = location.pathname === '/thankyou';

  return (
    <div className="sidebarcontainer">
      <nav className="sidebar">
          
          {(!isThankYouPage || user.role==='Admin') && (<div className="ul-container">           
            { (!user.registered || user.role==='Admin' || (user.registered && user.role==='Investor')) ? <NavLink className="navlink" to="/home" style={({ isActive }) => ({ color: isActive ? '#1aac83' : '#000', })}>{(user.role==='Admin')? 'Startups':'Data' }</NavLink> : null }                     
            { (user.role==='Admin') ? <NavLink className="navlink" to="/investors" style={({ isActive }) => ({ color: isActive ? '#1aac83' : '#000'})} >Investors</NavLink> : null}                        
            { (user.role==='Admin') ? <NavLink className="navlink" to="/manage" style={({ isActive }) => ({ color: isActive ? '#1aac83' : '#000'})} >Manage Access</NavLink> : null}                        
            { (user.role==='Admin') ? (<NavLink className="navlink " to="/createstartup" style={({ isActive }) => ({ color: isActive ? '#1aac83' : '#000'})} >Create Startup Profile</NavLink>) : null}
            { (user.role==='Admin') ? (<NavLink className="navlink" to="/createinvestor" style={({ isActive }) => ({ color: isActive ? '#1aac83' : '#000'})} >Create Investor Profile</NavLink>) : null}
            <NavLink className="navlink" to="/feedback" style={({ isActive }) => ({ color: isActive ? '#1aac83' : '#000'})} >Feedback</NavLink> 
          </div>)}
      </nav>
    </div>

  );
};

export default Sidebar;

// function menuItems({user}) {
//   if (user.role==='admin') {
//     return <h1>{user.name}'s Data Room</h1>
//   }
//   if (user.role==='startup') {
//     return <h1>Startup Registration</h1>
//   }
//   if (user.role==='investor') {
//     return <h1>Investor Registration</h1>
//   }
//   else {
//     return <h3>There is an error</h3>
//   }
  
//}
