import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext';


//pages and components
import StartupsBoard from './pages/StartupsBoard'
import Login from './pages/Login';
import Manage from './pages/Manage'
import StartupForm from './pages/StartupForm'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import ThankYou from './pages/ThankYou'
import StartupProfile from './components/StartupProfile';
import InvestorProfile from './components/InvestorProfile';
import InvestorHome from './pages/InvestorHome';
import InvestorForm from './pages/InvestorForm'
import Feedback from './pages/Feedback'
import InvestorsBoard from './pages/InvestorsBoard';



function App() {

  const { user } = useAuthContext();

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className='sideandpagecontainer'>
          {user ? <Sidebar/> : null}
          <div className="pages">
            <Routes>
              <Route path="*" element={user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

function AuthenticatedRoutes() {
  const { user } = useAuthContext();

  return (
    <Routes>
      <Route path="/home" element={<Home user={user} />} />
      <Route path="/startups" element={<StartupsBoard/>} />
      <Route path="/investors" element={<InvestorsBoard />} />
      <Route path="/startup/:id" element={<StartupProfile />} />
      <Route path="/investor/:id" element={<InvestorProfile />} />
      <Route path="/manage" element={<Manage />} />
      <Route path="/createstartup" element={<StartupForm />} />
      <Route path="/createinvestor" element={<InvestorForm />} />
      <Route path="/feedback" element={<Feedback/>} />
      <Route path="/thankyou" element={<ThankYou />} />
      <Route path="*" element={<Navigate to="/home" />} /> 
    </Routes>
  );
}

function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

function Home({user}) {
  if (user.role==='Admin') {
    return <StartupsBoard/>
  }
  if (user.role==='Startup') {
    if (!user.registered) {
      return <StartupForm/>
    }
    else {
      return <ThankYou/>
    }
  }
  if (user.role==='Investor') {
    if (!user.registered) {
      return <InvestorForm/>
    }
    else {
      return <InvestorHome/>
    }
  }
  else {
    return <h3>There is an error</h3>
  }
  
}

export default App;
