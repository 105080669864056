import {Link} from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useStartupsContext } from '../hooks/useStartupsContext';

const StartupPreview = ({ startup }) => {

  const {user}= useAuthContext()
  const {dispatch} = useStartupsContext()

  const formatDate= (date) => {
    const object=new Date(date)
    const options={day: '2-digit', month: '2-digit', year: 'numeric'}
    return object.toLocaleDateString('pt-PT', options)
  }
  
  const handleTrashClick = async (e) => {
    e.preventDefault()
    if (!user) {
      return 
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/startups/${startup._id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer: ${user.token}`
      }
    })
    //console.log(response)
    const json =await response.json()
    

    if (response.ok) {
      dispatch({type: 'DELETE_STARTUP', payload: json})
    }
  }



  return (

    <Link to={`/startup/${startup._id}`} className='no-underline'>
      <div className="entity-details">
        <div className="preview-header">
          <h4>{startup.name}</h4>
          {user.role==='Admin' && (<div className="trashcontainer">
            <FontAwesomeIcon icon={faTrash} className="green" onClick={handleTrashClick}/>
          </div>)}
        </div>
        <div className='flex'>
          <div>
            <p><strong>Founder: </strong>{startup.founder1name}</p>
            <p><strong>Incorporation: </strong>{formatDate(startup.dateOfIncorporation)}</p>
            <p><strong>Employees: </strong>{startup.numberOfEmployees}</p>
          </div>
          <div>
            <p><strong>Revenues Last Fiscal Year: </strong>${startup.revenuesLastYear}</p>
            <p><strong>Revenues Last 3 Months: </strong>${startup.revenuesLast3Months}</p>
            <p><strong>Annual Recurring Revenue: </strong>${startup.annualRecurringRevenue}</p>
          </div>
          <div>
            <p><strong>HQ City: </strong>{startup.hqCity}</p>
            <p><strong>HQ Country: </strong>{startup.hqCountry}</p>
            <p><strong>Amount being raised: </strong>${startup.amountBeingRaised}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StartupPreview;
