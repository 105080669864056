import { useEffect} from "react"
import InvestorPreview from "../components/InvestorPreview"
import { useInvestorsContext } from "../hooks/useInvestorsContext"
import {useAuthContext} from "../hooks/useAuthContext"


const InvestorsBoard = () => {
  const {investors, dispatch}= useInvestorsContext()
  const {user} = useAuthContext()

  useEffect(() => {
    const fetchInvestors = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/investors`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if (response.ok) {
        dispatch({type: 'SET_INVESTORS', payload: json})
      }
    }

    if (user) {
      fetchInvestors()
    }

  }, [dispatch, user])

  return (
    <div className="home">
      <div className="startups">
        {investors && investors.map(investor => (
          <InvestorPreview investor={investor} key={investor._id} />
        ))}
      </div>
    </div>
  )
}

export default InvestorsBoard