import AccessForm from '../components/AccessForm'
import { useEffect} from "react"
import { useUsersContext } from "../hooks/useUsersContext"
import {useAuthContext} from "../hooks/useAuthContext"
import {UserListing, UserListingHeader} from "../components/UserListing"


const Manage = () => {
  const {users, dispatch}= useUsersContext()
  const {user} = useAuthContext()

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/users`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if (response.ok) {
        dispatch({type: 'SET_USERS', payload: json})
      }
    }

    if (user) {
      fetchUsers()
    }

  }, [dispatch, user])
  
  return (
    <div className="managecontainer">
      <div className="managetitle">
        <h2 className='green' >Add Access</h2>
      </div>
      <div className="access-forms">
        <AccessForm role='Startup'/>
        <AccessForm role='Investor'/>
      </div>
      <div className='user-details-container'>
        <div className="user-details">
          {(users && users.filter((user)=>user.role==='Startup').length) ? <UserListingHeader key='startupheader'/> : null}
          {users && users
          .filter((user)=> (
            user.role==='Startup'
          ))
          .map(user => (
            <UserListing user={user} key={user._id} />
        ))}
        </div>
        <div className="user-details">
        {(users && users.filter((user)=>user.role==='Investor').length) ? <UserListingHeader key='investorheader'/> : null}
          {users && users
          .filter((user)=> (
            user.role==='Investor'
          ))
          .map(user => (
            <UserListing user={user} key={user._id} />
        ))}
        </div>
      </div>
    </div>
		
  )
}

export default Manage