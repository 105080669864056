import {useState} from 'react'
import { useLogin } from '../hooks/useLogin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const Login=() => {
	const [email, setEmail]=useState('')
	const [password, setPassword]=useState('')
	const [passwordVisible, setPasswordVisible]=useState(false)
	const {login, error, isLoading}=useLogin('')

	const togglePasswordVisiblity = () => {
		setPasswordVisible(passwordVisible => !passwordVisible);
	};
	

	const handleSubmit=async (e) => {
		e.preventDefault()
		await login(email, password)
	}

	return (
		<form className="login" onSubmit={handleSubmit}>
			<h3>Log in</h3>

			<label>Email: </label>
			<input
				type="email"
				onChange={(e)=> {setEmail(e.target.value)}}
				value={email}
			/>

			<label>Password: </label>
			<div className="passwordField">
				<input
					type={passwordVisible ? "text":"password"}
					onChange={(e)=> setPassword(e.target.value)}
					value={password}
				/>
				<FontAwesomeIcon 
          icon={passwordVisible ? faEye : faEyeSlash} 
          onClick={togglePasswordVisiblity} 
          className="eyeIcon"
        />
			</div>
			

			<button disabled={isLoading}>Log in</button>
			{error && <div className="errorMessage">{error}</div>}
		</form>
	)
}

export default Login