import { useState, useEffect} from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useInvestorsContext } from '../hooks/useInvestorsContext';
import {useNavigate} from 'react-router-dom'

import Dropdown from '../components/Dropdown'
import DropdownPlus from '../components/DropdownPlus' 
import options from '../objects/dropdownOptions'

function InvestorForm() {

	//set states for the dropdown menus
	const [selectedHqCountry, setSelectedHqCountry]=useState("Choose One")
	const [selectedPrimaryMarket, setSelectedPrimaryMarket]=useState("Choose One")
	const [selectedSecondaryMarkets, setSelectedSecondaryMarkets]=useState(["Choose up to 5"])
	const [selectedPrimarySDG, setSelectedPrimarySDG]=useState("Choose One")
	const [selectedOtherSDG, setSelectedOtherSDG]=useState(["Choose up to 5"])
	const [selectedInvestmentType, setSelectedInvestmentType]=useState("Choose One")


	//other setups
	const {dispatch:auth_dispatch}=useAuthContext()
	const {dispatch:investor_dispatch}=useInvestorsContext()
	const navigate=useNavigate()
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)
  	const [emptyFields, setEmptyFields]=useState([])
	const {user}=useAuthContext()
  	const [formData, setFormData] = useState({
	user_id: user._id,
    name: '',
	investorLinkedin:'',
    website: '',
    portfolioManagerName: '',
	portfolioManagerLinkedin:'',
    dateOfIncorporation: '',
    numberOfEmployees: '',
    aumLastYear: '',
    aumLast3Months: '',
    hqCity: '',
    hqCountry: '',
    primaryMarket: '',
    secondaryMarkets: [],
    investmentThesis: '',
    primarySDG: '',
    otherSDG: [],
    investmentType: '',
    minTicket: '',
  });

//set context
useEffect(() => {
    const fetchInvestors = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/investors`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if (response.ok) {
        investor_dispatch({type: 'SET_INVESTORS', payload: json})
      }
    }

    if (user) {
      fetchInvestors()
    }

  }, [investor_dispatch, user])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

	//Submit!
  const handleSubmit = async (e) => {
    e.preventDefault()
		setError(null)
		setSuccess(null)
		
		const investor={
			...formData,
			investmentType: selectedInvestmentType,
			primarySDG: selectedPrimarySDG,
			otherSDG: selectedOtherSDG,
			primaryMarket: selectedPrimaryMarket,
			secondaryMarkets: selectedSecondaryMarkets,
			hqCountry: selectedHqCountry
			}
		// for (const key in investor) {
		// 	console.log(investor[key])
		// }
		
		const formDataToSend = new FormData();


  	//Append the rest of the investor data
  	for (const key in investor) {
				if (key==='otherSDG') {
					investor.otherSDG.forEach((option) => {
						formDataToSend.append('otherSDG', option);
					});
				}
				else if (key==='secondaryMarkets') {
					investor.secondaryMarkets.forEach((option) => {
						formDataToSend.append('secondaryMarkets', option);
					});
				}
				else {
					formDataToSend.append(key, investor[key]); 
				}
  	}
		

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/investors`, {
				method: 'POST',
				body: formDataToSend, 
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			})
			const json = await response.json()
			console.log(json)
			
			if (!response.ok) {
				setError(json.error)
				setEmptyFields(json.emptyFields || [])
			}
			if (response.ok) {
				setEmptyFields([])
				setError(null)
				setSuccess(true)
				investor_dispatch({type: 'CREATE_INVESTOR', payload: json.investor})

				setTimeout(() => {
					if (user.role!=='Admin') {
						auth_dispatch({type: 'LOGIN', payload: json.user})	
					}
					navigate('/home');
				  }, 3000);
				//setFormData()
				
			}
		} catch {
			setError('Network Error: Cannot connect to backend')
		}
			
  };


  return (
    <form encType= "multipart/form-data" className='startupform' onSubmit={handleSubmit}>
			<h2>Investor Data Capture</h2>
			<p className="mandatory"><span className="required">*</span>means a mandatory field</p>
			<label>
				Name<span className="required">*</span>:
				<input
					type="text"
					name="name"
					value={formData.name}
					onChange={handleInputChange}
					className={emptyFields.includes('name') ? 'error':''}
				/>
			</label>

			<label>
				LinkedIn<span className="required">*</span>:
				<input
					type="text"
					name="investorLinkedin"
					value={formData.investorLinkedin}
					onChange={handleInputChange}
					className={emptyFields.includes('investorLinkedin') ? 'error':''}
				/>
			</label>

			<label>
				Website:
				<input
					type="text"
					name="website"
					value={formData.website}
					onChange={handleInputChange}
				/>
			</label>


			<label>
				Portfolio Manager Name<span className="required">*</span>:
				<input
					type="text"
					name="portfolioManagerName"
					value={formData.portfolioManagerName}
					onChange={handleInputChange}
					className={emptyFields.includes('portfolioManagerName') ? 'error':''}
				/>
			</label>

			<label>
				Portfolio Manager LinkedIn:
				<input
					type="text"
					name="portfolioManagerLinkedin"
					value={formData.portfolioManagerLinkedin}
					onChange={handleInputChange}
				/>
			</label>

			<label>
				Date of Incorporation<span className="required">*</span>:
				<input
					type="date"
					name="dateOfIncorporation"
					value={formData.dateOfIncorporation}
					onChange={handleInputChange}
					className={emptyFields.includes('dateOfIncorporation') ? 'error':''}
				/>
			</label>

			<label>
				Number of Employees<span className="required">*</span>:
				<input
					type="String"
					name="numberOfEmployees"
					value={formData.numberOfEmployees}
					onChange={handleInputChange}
					className={emptyFields.includes('numberOfEmployees') ? 'error':''}
				/>
			</label>

			<label>
				AUM Last Year<span className="required">*</span>:
				<input
					type="text"
					name="aumLastYear"
					value={formData.revenuesLastYear}
					onChange={handleInputChange}
					className={emptyFields.includes('aumLastYear') ? 'error':''}
				/>
			</label>

			<label>
				AUM Last 3 Months<span className="required">*</span>:
				<input
					type="text"
					name="aumLast3Months"
					value={formData.revenuesLast3Months}
					onChange={handleInputChange}
					className={emptyFields.includes('aumLast3Months') ? 'error':''}
				/>
			</label>

			<label>
				HQ City<span className="required">*</span>:
				<input
					type="text"
					name="hqCity"
					value={formData.hqCity}
					onChange={handleInputChange}
					className={emptyFields.includes('hqCity') ? 'error':''}
				/>
			</label>

			<label>
				HQ Country<span className="required">*</span>:
				<Dropdown selected={selectedHqCountry}  
				setSelected={setSelectedHqCountry} 
				options={options.countries} 
				missing={emptyFields.includes('hqCountry') ? true : false} />
			</label>

			<label>
				Primary Market<span className="required">*</span>:
				<Dropdown selected={selectedPrimaryMarket}  
				setSelected={setSelectedPrimaryMarket} 
				options={options.countries}
				missing={emptyFields.includes('primaryMarket') ? true : false} />
			</label>

			<label>
				Secondary Markets<span className="required">*</span>:
				<DropdownPlus selected={selectedSecondaryMarkets}  
				setSelected={setSelectedSecondaryMarkets} 
				options={options.countries} 
				primaryMarket={selectedPrimaryMarket} 
				missing={emptyFields.includes('secondaryMarkets') ? true : false}
				numberOfOptions={5} />
			</label>
			<label>
				Investment Thesis<span className="required">*</span>:
				<textarea
					name="investmentThesis"
					value={formData.investmentThesis}
					onChange={handleInputChange}
					className={emptyFields.includes('investmentThesis') ? 'error':''}
				/>
			</label>

			<label>
				Primary SDG<span className="required">*</span>:
				<Dropdown selected={selectedPrimarySDG}
				  setSelected={setSelectedPrimarySDG}
					options={options.sdg}
					missing={emptyFields.includes('primarySDG') ? true : false} />
			</label>

			<label>
				Other SDGs<span className="required">*</span>:
				<DropdownPlus selected={selectedOtherSDG}  
				setSelected={setSelectedOtherSDG} 
				primaryMarket={selectedPrimarySDG} 
				options={options.sdg} 
				missing={emptyFields.includes('otherSDG') ? true : false}
				numberOfOptions={4} />

			</label>

			<label>
				Investment Type<span className="required">*</span>:
				<Dropdown selected={selectedInvestmentType}  
				setSelected={setSelectedInvestmentType} 
				options={options.investmentType}
				missing={emptyFields.includes('investmentType') ? true : false} />
			</label>

			<label>
				Minimum Ticket<span className="required">*</span>:
				<input
					type="text"
					name="minTicket"
					value={formData.minTicket}
					onChange={handleInputChange}
					className={emptyFields.includes('minTicket') ? 'error':''}
				/>
			</label>


		<div className='add'>
			<button>Submit</button>
			{error && <div className="errorMessage">{error}</div>}
			{success && (<div className="success">Thank you for registering! Redirecting...</div>)}
		</div>
</form>



  );
}

export default InvestorForm;
