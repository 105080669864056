import {useState, useEffect, useRef} from 'react';

function Dropdown({selected, setSelected, options, missing}) {
	const [isActive, setIsActive] =useState(false) 

		//close when out of bounds
		const dropdownRef = useRef(null);

		useEffect(() => {
			const closeDropdownOnOutsideClick = (event) => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setIsActive(false);
				}
			};
	
			document.addEventListener('click', closeDropdownOnOutsideClick);
	
			return () => {
				document.removeEventListener('click', closeDropdownOnOutsideClick);
			};
		}, []);

	return (
		<div className="dropdown" ref={dropdownRef}>
			<div className={`dropdown-btn ${missing ? 'error':''}`} onClick={(e) => setIsActive(!isActive)}>
				{selected}
				<span className="caret-down"></span>
			</div>
			{isActive && (
			<div className="dropdown-content">
				{options.map((option)=> (
					<div key={option} onClick={(e)=> {
						setSelected(option)
						setIsActive(false)
					}}
					className="dropdown-item">{option}</div>
				))}
			
			</div>	
			)}
		</div>
	)
}

export default Dropdown