import {Link} from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom'



const Navbar = () => {
	const {logout}= useLogout()
	const {user} =useAuthContext()
	const navigate=useNavigate()


	const handleClick=()=> {
		logout()
		navigate('/login')
	}
	
	return (
		<header>
			<div className="navcontainer">
				<Link to='/'>
					{user ? <Title user={user}/> : <h1>Project Data Room</h1>}
				</Link>
				<nav>
					{user && (
						<div>
							<span>{user.email}</span>
							<button onClick={handleClick}>Log out</button>
						</div>
						)}
				</nav>
			</div>
		</header>
	)
}

function Title({user}) {
  if (user.role==='Admin') {
    return <h1>Project Data Room</h1>
  }
  if (user.role==='Startup') {
		if (user.registered) return <h1>Startup Profile</h1>
    else return <h1>Startup Registration</h1>

  }
  if (user.role==='Investor') {
    return user.registered ? (<h1>Project Data Room</h1>) : (<h1>Investor Registration</h1>) 
  }
  else {
    return <h3>There is an error</h3>
  }
  
}

export default Navbar