import { useState } from "react";
import { useAuthContext } from './useAuthContext'

export const useLogin=()=> {
	const [error, setError] =useState(null)
	const [isLoading, setIsLoading]=useState(null)
	const {dispatch} =useAuthContext()

	const login =async (email, password) => {
		//console.log(email, password)
		//console.log(`${process.env.REACT_APP_BACKEND_URL}/user/login`)
		setIsLoading(true)
		setError(null)

		const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/login`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({email, password})
		})
		const json =await response.json()
		//console.log(json)
	

		if (!response.ok) {
			setIsLoading(false)
			setError(json.error)
		}

		if (response.ok) {
			//save the user to local storage
			sessionStorage.setItem('user', JSON.stringify(json))

			//update auth context
			dispatch({type: 'LOGIN', payload: json})

			setIsLoading(false)
		}
	}

	return {login, isLoading, error}
}
