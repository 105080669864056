import { useInvestorsContext } from "../hooks/useInvestorsContext"
import {useParams} from 'react-router-dom'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {SocialIcon} from 'react-social-icons'


const InvestorProfile = () => {

  const {investors}= useInvestorsContext()
  const {id} =useParams()

  const investor =investors.find(investor => investor._id ===id)
  const index=investors.indexOf(investor)
  //console.log(investor)

  const formatDate= (date) => {
    const object=new Date(date)
    const options={day: '2-digit', month: '2-digit', year: 'numeric'}
    return object.toLocaleDateString('pt-PT', options)
  }

  return (
    <div className="full-width">
      <div className="pagenavigate">
        {index !== 0 && (
          <Link
            to={index === 0 ? '/home' : `/investor/${investors[index - 1]._id}`}
            className="inpagelink"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="caret-sides" />
            Back
          </Link>
        )}
        {index !== investors.length - 1 && (
          <Link
            to={`/investor/${investors[index + 1]._id}`}
            className="inpagelink"
          >
            Next
            <FontAwesomeIcon icon={faChevronRight} className="caret-sides" />
          </Link>
        )}
      </div>
  
      <div className="profilecontainer" id="investorprofile">
        <div className="profilesubcontainer">
          <div className="headercontainer">
            <div>
              <h1>{investor.name}</h1>
              {investor.website && (<p><strong>Website: </strong> <a href={`https://${investor.website}`} target="_blank" rel="noopener noreferrer" className="inpagelink">{investor.website}</a></p>)}
              <p><strong>HQ: </strong>{investor.hqCity}, {investor.hqCountry}</p>
              <p><strong>Incorporated on: </strong>{formatDate(investor.dateOfIncorporation)}</p>
            </div>
          </div>
        </div>
        
        <div className='profilesubcontainer'>
          <div>
              <h2>Investor & <br/>Portfolio Manager</h2>
              <div className='founders'>
                <p>{`${investor.name}`} </p>
                <SocialIcon url="www.linkedin.com" href={`https://www.linkedin.com/in/${investor.linkedin}`} target="_blank" style={{ height: 25, width: 25, margin:'5px' }} />
              </div>
                <div className='founders'>
                  <p>{`${investor.portfolioManagerName}`}  </p>
                {investor.portfolioManagerLinkedin && (<SocialIcon url="www.linkedin.com" href={`https://www.linkedin.com/in/${investor.founder2linkedin}`} target="_blank" style={{ height: 25, width: 25, margin:'5px' }} />)}
              </div>
            </div>
          <div className="profileblock">
            <h2>Investment Thesis</h2>
            <p>{investor.investmentThesis}</p>
          </div>
          
        </div>
        
        <div className='profilesubcontainer smaller'>
          <div className="profileblock">
            <h2>Market</h2>
            <p><strong>Primary: </strong>{investor.primaryMarket}</p>
            {(investor.secondaryMarkets.length-1) ? (<p><strong>Secondary: </strong> {investor.secondaryMarkets.slice(1).join(', ')}</p>) : null } 

          </div>
          <div>
            <h2>Employees</h2>
            <div className='center'><h1>{investor.numberOfEmployees}</h1></div>
          </div>
        </div>

        <div className='sdgcontainer'>
          <div className='primarySDG'>
            <img className="imgInsideContainer" src={`/SDGIcons/${investor.primarySDG.slice(0,1)}.png`} alt='SDG'/>
          </div>
          {(investor.otherSDG.length-1) ? (investor.otherSDG.slice(1).map((sdg) => (
          <div key={sdg} className='otherSDG'>
            <img className="imgInsideContainer" src={`/SDGIcons/${sdg.slice(0,1)}.png`} alt='SDG'/>
          </div>))) : null }

        </div>

        <h2 className="green">Financials</h2>
        <div className="profilesubcontainer">
          <div>
            <p><strong>AUM | Last Fiscal Year: </strong>{`$${investor.aumLastYear}`}</p>
            <p><strong>AUM | Last 3 Months: </strong>{`$${investor.aumLast3Months}`}</p>
          </div>
          <div>
            <p><strong>Investment Type: </strong>{investor.investmentType}</p>
            <p><strong>Min Ticket: </strong>{`$${investor.minTicket}`}</p>
          </div>
        </div>
        

      </div>

      
    </div>
  );
}  

export default InvestorProfile