import {createContext, useReducer} from 'react'

export const StartupsContext =createContext()

export const startupsReducer = (state, action) => {
	switch (action.type) {
		case 'SET_STARTUPS':
			return {
				startups: action.payload
			}
		case 'CREATE_STARTUP':
			return {
				startups: [action.payload, ...state.startups]
			}
		case 'DELETE_STARTUP':
			return {
				startups: state.startups.filter((w)=> w._id !== action.payload._id)

		
			}
		default:
			return state

	}
}

export const StartupsContextProvider = ({children}) => {

	const [state, dispatch]=useReducer(startupsReducer, {
		startups: null
	})

	
	return (
		<StartupsContext.Provider value={{...state, dispatch}}>
			{children}
		</StartupsContext.Provider>
	)
}