import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

function FileUpload({ onFileUpload, missing}) {
  const [uploadedFile, setUploadedFile] = useState(null); // Initialize as null

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
    onFileUpload(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div className={`dropzone ${isDragActive ? "active" : ""} ${missing ? 'error':''}`}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop files here, or click to select file</p>
        {uploadedFile && (
          <p>
            Current: <span className="currentFile">{uploadedFile.name}</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default FileUpload;


