const options = {
	fundingOptions: [
		'Equity (common shares)',
		'Equity (preferred shares)',
		'Debt (term loan)',
		'Debt (revolving facility)',
		'SAFE'
	],
	investmentType: [
		'Equity (common shares)',
		'Equity (preferred shares)',
		'Debt (term loan)',
		'Debt (revolving facility)',
		'SAFE',
		'Multi-asset'
	],
	sdg: [
		'1: No Poverty',
		'2: Zero Hunger',
		'3: Good Health and Well-Being',
		'4: Quality Education',
		'5: Gender Equality',
		'6: Clean Water and Sanitation',
		'7: Affordable and Clean Energy',
		'8: Decent Work and Economic Growth',
		'9: Industry, Innovation and Infrastructure',
		'10: Reduced Inequalities',
		'11: Sustainable Cities and Communities',
		'12: Responsible Consumption and Production',
		'13: Climate Action',
		'14: Life Below Water',
		'15: Life on Land',
		'16: Peace, Justice and Strong Institutions',
		'17: Partnerships for the Goals'
	],
	countries: [
		"Afghanistan",
		"Albania",
		"Algeria",
		"Andorra",
		"Angola",
		"Antigua and Barbuda",
		"Argentina",
		"Armenia",
		"Australia",
		"Austria",
		"Azerbaijan",
		"Bahamas",
		"Bahrain",
		"Bangladesh",
		"Barbados",
		"Belarus",
		"Belgium",
		"Belize",
		"Benin",
		"Bhutan",
		"Bolivia",
		"Bosnia and Herzegovina",
		"Botswana",
		"Brazil",
		"Brunei",
		"Bulgaria",
		"Burkina Faso",
		"Burundi",
		"Cabo Verde",
		"Cambodia",
		"Cameroon",
		"Canada",
		"Central African Republic",
		"Chad",
		"Chile",
		"China",
		"Colombia",
		"Comoros",
		"Congo (Congo-Brazzaville)",
		"Costa Rica",
		"Croatia",
		"Cuba",
		"Cyprus",
		"Czechia",
		"Democratic Republic of the Congo (Congo-Kinshasa)",
		"Denmark",
		"Djibouti",
		"Dominica",
		"Dominican Republic",
		"East Timor (Timor-Leste)",
		"Ecuador",
		"Egypt",
		"El Salvador",
		"Equatorial Guinea",
		"Eritrea",
		"Estonia",
		"Eswatini (fmr. 'Swaziland')",
		"Ethiopia",
		"Fiji",
		"Finland",
		"France",
		"Gabon",
		"Gambia",
		"Georgia",
		"Germany",
		"Ghana",
		"Greece",
		"Grenada",
		"Guatemala",
		"Guinea",
		"Guinea-Bissau",
		"Guyana",
		"Haiti",
		"Holy See",
		"Honduras",
		"Hungary",
		"Iceland",
		"India",
		"Indonesia",
		"Iran",
		"Iraq",
		"Ireland",
		"Israel",
		"Italy",
		"Ivory Coast",
		"Jamaica",
		"Japan",
		"Jordan",
		"Kazakhstan",
		"Kenya",
		"Kiribati",
		"Kuwait",
		"Kyrgyzstan",
		"Laos",
		"Latvia",
		"Lebanon",
		"Lesotho",
		"Liberia",
		"Libya",
		"Liechtenstein",
		"Lithuania",
		"Luxembourg",
		"Madagascar",
		"Malawi",
		"Malaysia",
		"Maldives",
		"Mali",
		"Malta",
		"Marshall Islands",
		"Mauritania",
		"Mauritius",
		"Mexico",
		"Micronesia",
		"Moldova",
		"Monaco",
		"Mongolia",
		"Montenegro",
		"Morocco",
		"Mozambique",
		"Myanmar (formerly Burma)",
		"Namibia",
		"Nauru",
		"Nepal",
		"Netherlands",
		"New Zealand",
		"Nicaragua",
		"Niger",
		"Nigeria",
		"North Korea",
		"North Macedonia (formerly Macedonia)",
		"Norway",
		"Oman",
		"Pakistan",
		"Palau",
		"Palestine State",
		"Panama",
		"Papua New Guinea",
		"Paraguay",
		"Peru",
		"Philippines",
		"Poland",
		"Portugal",
		"Qatar",
		"Romania",
		"Russia",
		"Rwanda",
		"Saint Kitts and Nevis",
		"Saint Lucia",
		"Saint Vincent and the Grenadines",
		"Samoa",
		"San Marino",
		"Sao Tome and Principe",
		"Saudi Arabia",
		"Senegal",
		"Serbia",
		"Seychelles",
		"Sierra Leone",
		"Singapore",
		"Slovakia",
		"Slovenia",
		"Solomon Islands",
		"Somalia",
		"South Africa",
		"South Korea",
		"South Sudan",
		"Spain",
		"Sri Lanka",
		"Sudan",
		"Suriname",
		"Sweden",
		"Switzerland",
		"Syria",
		"Tajikistan",
		"Tanzania",
		"Thailand",
		"Timor-Leste",
		"Togo",
		"Tonga",
		"Trinidad and Tobago",
		"Tunisia",
		"Turkey",
		"Turkmenistan",
		"Tuvalu",
		"Uganda",
		"Ukraine",
		"United Arab Emirates",
		"United Kingdom",
		"United States of America",
		"Uruguay",
		"Uzbekistan",
		"Vanuatu",
		"Venezuela",
		"Vietnam",
		"Yemen",
		"Zambia",
		"Zimbabwe"
	],
	industries: [
		"Agriculture, Forestry, and Fishing",
		"Mining and Quarrying",
		"Manufacturing",
		"Electricity, Gas, Steam, and Air Conditioning Supply",
		"Water Supply, Sewerage, Waste Management, and Remediation Activities",
		"Construction",
		"Wholesale and Retail Trade",
		"Transportation and Storage",
		"Accommodation and Food Service Activities",
		"Information and Communication",
		"Financial and Insurance Activities",
		"Real Estate Activities",
		"Professional, Scientific, and Technical Activities",
		"Administrative and Support Service Activities",
		"Public Administration and Defense; Compulsory Social Security",
		"Education",
		"Human Health and Social Work Activities",
		"Arts, Entertainment, and Recreation",
		"Other Service Activities",
		"Activities of Households as Employers",
		"Activities of Extraterritorial Organizations and Bodies",
	]
}

export default options